<template>
  <div class="container pd100">
    <div class="card" :style="{'background-image': 'url('+course_cate.category_cover+')'}">
      <!-- <van-image class="card-img" :src="course_cate.category_cover" alt="banner" /> -->
      <div class="card-info">
        <div class="card-hd">{{ course_cate_fee.fee_type_name }}</div>
        <div class="card-bd">
          <div class="card-title">{{ course_cate.category_title }}</div>
          <div class="card-txt "> ￥ {{ course_cate_fee.price }}</div>
        </div>
      </div>
    </div>
    <!-- <a :href="'tel:'+detail.detail_telphone" style="display:block" class="van-hairline--bottom">
      <van-cell
        :title="detail.meeting_hotline"
        icon="phone-o"
        is-link
      />
    </a>
    <van-cell :title="detail.meeting_address" icon="location-o" />
    <van-cell :title="detail.meeting_price" icon="gold-coin-o" />
    <van-cell class="mb10" :title="detail.meeting_start_date + ' - ' + detail.meeting_end_date" icon="clock-o" />
    <van-cell class="mb10" title="报名人数" :value="detail.already_count + ' / ' + detail.ticket_count" />
-->
    <van-cell title="上课校区" />
    
    <div class="desc bgf mb10">
      {{ studio.studio_name }}
    </div>
    <!-- <van-cell title="课程列表" />
    <div class="class-list mb10">
      <div v-for="(item,index) in course_list" :key="index" class="class-list-item">
        <div class="class-list-item-dot" />
        <div class="class-list-item-title">{{ item }}</div>
      </div>
    </div> -->

    <div class="agree" @click="onAgree">
        <van-checkbox v-model="agree" icon-size="16px" checked-color="#00DE93">
            <span>我已阅读并同意</span>
            <span @click.stop="toAgreement" style="color:#00DE93">《学员协议》</span>
        </van-checkbox>

        <!-- <van-radio-group v-model="agree">
          <van-radio icon-size="16px" class="mr5" name="1" />
        </van-radio-group> 
        我已阅读并同意
        <span class="ui-color" @click.stop="toAgreement">《学员协议》</span> -->
    </div>
    <div class="affix-bar">
      <van-button
        class="affix-bar-btn"
        block
        type="primary"
        @click="onSubmit"
      >确认购买</van-button>
    </div>
    <van-popup v-model="isAgreement" closeable close-icon="close" round position="bottom" :style="{ height: '500px',overflow: 'auto',paddingTop:'30px',paddingRight:'10px',paddingLeft:'10px',paddingBottom:'30px'}">
        <div class="agreement ql-editor" v-html="agreementHtml"></div>
    </van-popup>
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'
import { Radio, RadioGroup , Popup , Checkbox, CheckboxGroup,} from 'vant'
import { wxPay } from '@/common/wx'

export default {
  name: 'PremiumDetail',
  components: {
    [Radio.name]: Radio,
    [Popup.name]: Popup,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [RadioGroup.name]: RadioGroup
  },
  mixins: [PageMixin],
  data() {
    return {
      isAgreement: false,
      agreementHtml: '',
      course_cate: {},
      course_cate_fee: {},
      studio: {},
      course_consultant_id: '',
      course_list: {},
      agree: false
    }
  },
  created() {
    this.onDetail()
  },
  methods: {
    onDetail() {
      this.$toast.loading({
        mask: true,
        message: '加载中...',
        duration: 0
      })
      this.$api.premium_detail({ fee_id: this.$route.query.fee_id, studio_id: this.$route.query.studio_id }).then(res => {
        this.$toast.clear()
        this.course_cate = res.data.course_cate
        this.course_cate_fee = res.data.course_cate_fee
        this.studio = res.data.studio
        this.course_list = res.data.course_list
        this.getAagreement(this.studio.studio_id)
      })
    },
    toAgreement(){
        this.isAgreement = true
    },
    async getAagreement(id){
        await this.$api.premium_agreement({studio_id:id})
        .then(res=>{
            this.agreementHtml = res.data.agreement
        })
    },
    onAgree() {
      if (this.agree === '1') {
        this.agree = '0'
      } else {
        this.agree = '1'
      }
    },
    onSubmit() {
      if(!this.agree){
        this.$toast("请勾选已阅读并同意学员协议。");
        return false;
      }
      const params = {
        fee_id: this.$route.query.fee_id,
        studio_id: this.$route.query.studio_id,
        course_consultant_id: this.$route.query.course_consultant_id
      }
      this.$toast.loading({
        mask: true,
        message: '提交中...',
        duration: 0
      })
      this.$api.payment_contract(params)
        .then(res => {
          this.$toast.clear()
          localStorage.setItem('money',Number(this.course_cate_fee.price)*100)
          this.$router.push({path:'/orderCenter',query:{out_trade_no:res.data.out_trade_no}})
          // const { appId, timeStamp, nonceStr, package: packageStr, signType, paySign } = res.data.jsApiParameters
          // wxPay( appId, timeStamp, nonceStr, packageStr, signType, paySign)
          //   .then(res => {
          //     this.$dialog.alert({
          //       title: '课程购买成功',
          //       message: `课程：${this.course_cate.category_title}`,
          //       confirmButtonText: '返回我的'
          //     })
          //       .then(() => {
          //         // on close
          //         this.$router.replace({
          //           path: `/user`
          //         })
          //       })
          //   })
          //   .catch(err => {
          //     this.$dialog.alert({
          //       title: '支付失败',
          //       message: '课程购买失败',
          //       confirmButtonText: '重新尝试'
          //     })
          //       .then(() => {
          //         // on close
          //       })
          //     console.error(err)
          //   })
        })
        .catch(err => {
          this.$toast.fail('课程购买失败')
          console.error(err)
        })
    }
  }
}
</script>

<style lang="less" scoped>
  .card {
    margin: 10px 15px;
    height: 180px;
    position: relative;
    background-size: cover;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    &-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 180px;
      border-radius: 8px;
      overflow: hidden;
    }
    &-info{
      color: #fff;
      padding: 30px 20px 10px;
    }
    &-hd{
      font-size: 12px;
      margin-bottom: 10px;

    }
    &-title{
      font-size: 18px;
      font-weight: bold;
      max-width: 70%;
    }
    &-bd{
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
  .desc{
    padding: 10px 15px;
  }

  .class-list{
    &-item{
      box-sizing: border-box;
      background: #fff;
      padding: 0 15px;
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &-dot{
        width: 8px;
        height: 8px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 5px;
        background: #00DE93;
      }
    }
  }
  .agree{
    padding: 0 15px;
    height: 44px;
    font-size: 12px;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    float: left;
  }
</style>

